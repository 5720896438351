import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';
import Vehicleutilization from './vehicleutilization';

export default function Vehiclesutilization({}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const [displaySideDock, updateDisplaySideDock] = React.useState(false);
  const [selectedObjectId, updateSelectedObjectId] = React.useState(false);
  const columns = [
    {
        headerName: 'ID',
        width: 85,
        field: 'car_id',
        filter: true,
        pinned: 'left',
        cellRenderer: params => {
            let val = params.value
            if(displaySideDock===false)
            {
                let link = '/vehicleutilization/' + String(val) 
                return( <NavLink to={link} index = {val}>{val}</NavLink>)
            } else {
                return( <Text><Button style={{border:'none', color:'#0000EE', fontWeight: 'normal', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => selectObject(val)}>{val}</Button></Text>)
            }
        }
    },
      {
        headerName: 'Name',
          field: 'name',
          filter: true
      },
      {
        headerName: 'Status on Day 0',
          field: 'status_on_day_0',
          filter: true,
          cellRenderer: params => {
              let val = params.value
              if(val==='inactive'||val==='"inactive"')
              {
                val = 'unrentable';
              }
              if(val==='"active"')
              {
                val = 'active';
              }
              if(val==='"retired"')
              {
                val = 'retired';
              }
              return val
          }
      },
      {
        headerName: 'Day 0',
          field: 'day_0',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -1',
          field: 'day_1',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -2',
          field: 'day_2',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -3',
          field: 'day_3',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -4',
          field: 'day_4',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -5',
          field: 'day_5',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -6',
          field: 'day_6',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -7',
          field: 'day_7',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -8',
          field: 'day_8',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -9',
          field: 'day_9',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -10',
          field: 'day_10',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -11',
          field: 'day_11',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -12',
          field: 'day_12',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -13',
          field: 'day_13',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -14',
          field: 'day_14',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -15',
          field: 'day_15',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -16',
          field: 'day_16',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -17',
          field: 'day_17',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -18',
          field: 'day_18',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -19',
          field: 'day_19',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -20',
          field: 'day_20',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -21',
          field: 'day_21',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -22',
          field: 'day_22',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -23',
          field: 'day_23',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -24',
          field: 'day_24',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -25',
          field: 'day_25',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -26',
          field: 'day_26',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -27',
          field: 'day_27',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -28',
          field: 'day_28',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -29',
          field: 'day_29',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -30',
          field: 'day_30',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Day -31',
          field: 'day_31',
          filter: true,
          width: 100,
          cellRenderer: params => {
              let val = params.value
              let display = String(val) + '%'
              return( display )
          }
      },
      {
        headerName: 'Make Model Year',
          field: 'make_model_year',
          filter: true
      },
      {
        headerName: 'VIN',
          field: 'vin',
          filter: true
      },
      {
        headerName: 'Region',
          field: 'region',
          filter: true
      },
      {
        headerName: 'Timezone',
          field: 'timezone',
          filter: true
      },
      {
        headerName: 'First Inactive',
          field: 'first_inactive',
          filter: true
      },
      {
        headerName: 'First Active',
          field: 'first_active',
          filter: true
      },
      {
        headerName: 'First Shift',
          field: 'first_shift',
          filter: true
      }
  ];
  
  async function selectObject(val) {
    updateDisplaySideDock(false);
    await updateSelectedObjectId(val);
    updateDisplaySideDock(true);
}

  async function fetchPageData() {
    updateIsLoading(true)
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    const myInit = {queryStringParameters:{ 
        "back_end": process.env.REACT_APP_BACKEND,
        "jwt": jwt,
        "api_endpoint":"vehiclesutilization"
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log('vehiclesutilization response: '+JSON.stringify(res))
        updateRes(res)
        updateIsLoading(false)
    }
    catch (e) {
        console.log('vehiclesutilization error: '+toString(e));
        updateIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count])



  return (
    <Flex   //  https://ui.docs.amplify.aws/react/components/flex#demo
      direction='column'      //direction={{ base: 'column', large: 'row' }}
      //justifyContent='normal'           justifyContent={{ base: 'flex-start', large: 'flex-start' }}        //justifyContent="center"
      //alignItems='stretch'              alignItems={{ base: 'stretch', large: 'flex-start' }}       //alignItems="center" // alignItems="flex-end" (right)
      //alignContent='normal'             alignContent="flex-start"       //alignContent="center"
      wrap="wrap" //nowrap
      gap="1rem"
    >
        <View style={{padding:"1rem"}}>
          <Flex 
              direction='column'
          >
            <Heading level={1}>Fleet Utilization</Heading>
            <Text style={{width:'100%'}}>Below is the percent of each day that every vehicle in your fleet was rented for the last 31 days. Click the blue link to see detailed diagnostics on any vehicle.</Text>
            <View>
                <Button onClick={() => setCount(count + 1)}>
                    PRESS TO GET LATEST UPDATES
                </Button>
            </View>
            <Divider />
            <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>  
            {
              is_loading ? <Loadingmini /> : 
                <Flex wrap="wrap" gap="2rem" >
                  <Flex direction="column" style={displaySideDock ? {width:"25vw"} : {width:"80vw",maxWidth:"2200px"}}>
                      <Divider />
                      <View 
                          className="ag-theme-quartz" // applying the grid theme
                          style={{ height: 750 }} // the grid will fill the size of the parent container
                      >
                        <AgGridReact
                            rowData={res.resp}
                            columnDefs={columns}
                            rowSelection={'multiple'}
                            paginationPageSize={500}
                            paginationPageSizeSelector={[10, 25, 50]}
                            enableCellTextSelection={true}
                        />
                      </View>
                      <Divider />
                  </Flex>
                  {
                      displaySideDock === false ? 
                      <Flex direction="column">
                          <Divider />
                          <Button onClick={() => updateDisplaySideDock(true)}>
                              ACTIVATE SIDE DOCK
                          </Button>
                          <Divider />
                      </Flex> 
                      :
                      selectedObjectId===false || selectedObjectId < 1 ? <Text><i>Please select a vehicle by clicking its ID in the table...</i></Text> :
                      <Flex direction="column" style={{width:"55vw"}}>
                          <Divider />
                          <Text><Button style={{border:'none', fontStyle: 'italic', marginLeft:'-15px'}} onClick={() => updateDisplaySideDock(false)}>To open vehicles in full-screen mode, click here to hide the side dock.</Button></Text>
                          <Vehicleutilization paramVehicleId={selectedObjectId} />
                          <Divider />
                      </Flex>
                  }
                </Flex>
            }
          </Flex>
        </View>
    </Flex>
  );
}
