import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Text,Flex, View, Divider, Heading, Button} from '@aws-amplify/ui-react';
import Loadingmini from '../components/loadingmini';
import Vehiclecommands from './vehiclecommands';
import Vehiclemap from './vehiclemap';
import Vehicleinfo from './vehicleinfo';
import Vehicleinsurancedata from './vehicleinsurancedata';
//import Vehiclemaintenancehistory from './vehiclemaintenancehistory';
import Vehiclestatus from './vehiclestatus';
import Vehiclereservations from './vehiclereservations';
import Vehiclecommandhistory from './vehiclecommandhistory';
import Vehicleutilization from './vehicleutilization';
import Accidentinspector from './accidentinspector';



export default function Vehicle({paramVehicleId}) {
  let params = useParams();
  let tvehicleId = false
  if(paramVehicleId)
  {
    tvehicleId = paramVehicleId;
  } else {
    tvehicleId = params.vehicleId;
  }
  const [vehicleId, updateVehicleId] = React.useState(tvehicleId);
  const [vehicle, updateVehicle] = useState({status:"If this message stays - email us at info@joinago.com",rows:[]})
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [showDataPacket, updateShowDataPacket] = React.useState(false);
  const [showEngineData, updateShowEngineData] = React.useState(false);
  
  const fetchVehicleData = async () => {
    var thisVehicleId = vehicleId;
    if(paramVehicleId > 0)
    {
      thisVehicleId = paramVehicleId;
      updateVehicleId(thisVehicleId);
    }
    //console.log('fetching vehicle data vehicle '+thisVehicleId);
    updateIsLoading(true);
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let user = await Auth.currentAuthenticatedUser(); 
    const myInit = {queryStringParameters:{ 
        back_end: process.env.REACT_APP_BACKEND, 
        jwt:jwt,
        api_endpoint:"vehicles",
        user_pool_id: user.pool.userPoolId,
        vehicle_id: thisVehicleId
    }};
    //console.log(JSON.stringify(myInit));
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        if(res['status']=='success')
        {
          //console.log(JSON.stringify(res))
          updateVehicle(res);
          updateIsLoading(false);
          return
        } else {
          updateVehicle(res);
          //updateVehicleLoadError(res)
        }
    }
    catch (e) {
        const res = {status:"Error retrieving Vehicles or User not permissioned - email us at info@joinago.com",rows:[]};
        console.log(JSON.stringify(res))
        console.log('e:'+JSON.stringify(e))
        //updateVehicleLoadError(true)
        updateIsLoading(false)
        return
    }
  }

  useEffect(() => {
    fetchVehicleData()
  },[count,params])

  
  

  return (
    is_loading ? <Loadingmini /> : 
      <Flex //  https://ui.docs.amplify.aws/react/components/flex#demo
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Flex>
                <Heading level={1}>
                    {
                      vehicle.rows[0] ? "name" in vehicle.rows[0] ? 
                        vehicle.rows[0]["name"]!=="" ? 
                            vehicle.rows[0]["name"] 
                          : 
                            "Vehicle " + vehicleId
                        : 
                          "Vehicle " + vehicleId
                      : 
                        "Vehicle " + vehicleId
                    }
                </Heading>
            </Flex>      
            {
              vehicle.rows[0] ? 
                  "make_model_year" in vehicle.rows[0] ? 
                      <View><Heading level={4}>{vehicle.rows[0]["make_model_year"]}</Heading><br/></View>
                    : undefined 
                  : undefined
            }
            <Button onClick={() => setCount(count + 1)}>
                PRESS TO GET LATEST UPDATES
            </Button>
            <Divider />
            {vehicle.status !== "success" ? <Text>${vehicle.status}</Text>:undefined}
            <Flex wrap="wrap" justifyContent="center" gap="1rem">
              <Flex wrap="wrap" justifyContent="center" gap="1rem">
                <Flex direction='column'>
                  <Vehicleinfo vehicle={vehicle} />
                  <Vehiclecommands vehicle={vehicle} />
                </Flex>
                <Vehiclemap vehicle={vehicle} />
              </Flex>
              <Vehiclereservations vehicle={vehicle} />
              <Vehiclestatus vehicle={vehicle} />
              <Vehiclecommandhistory vehicle={vehicle} />
              <Vehicleinsurancedata vehicle={vehicle} />
              {
                vehicle.rows.length > 0 ?
                'car_id' in vehicle.rows[0] ?
                  <Vehicleutilization paramVehicleId={vehicle.rows[0]['car_id']} />
                : undefined : undefined
              }
              <Accidentinspector vehicle={vehicle} />
            </Flex>
            <Flex wrap="wrap" justifyContent="center" gap="1rem">
              {vehicle ?
                  vehicle.rows ?
                    vehicle.rows[0] ?  
                      <Flex direction="column">
                        {
                          "data" in vehicle ?
                            showEngineData === false ? <Button onClick={() => updateShowEngineData(true)}>SHOW ENGINE DATA</Button> :
                              <Flex direction="column">
                                <Heading level={2}>Engine Data</Heading>
                                <pre>
                                  {vehicle.data}
                                </pre>
                                <Button onClick={() => updateShowEngineData(false)}>HIDE ENGINE DATA</Button>
                              </Flex>
                          : undefined
                        }
                        <Divider />
                        {
                          showDataPacket ?
                            <Flex direction="column">
                              <Heading level={2}>Data Packet</Heading>
                              <pre id="json"><p>{JSON.stringify(vehicle.rows[0],null, 4)}</p></pre>
                              <Button onClick={() => updateShowDataPacket(false)}>
                                Hide Data Packet
                              </Button>
                            </Flex>
                          : 
                            <Button onClick={() => updateShowDataPacket(true)}>
                              Vehicle Data Packet
                            </Button>
                        }
                        <br/><br/><br/><br/>
                      </Flex>
                    : undefined
                  : undefined
                : undefined}
            </Flex>
        </View>
      </Flex>
    );
}

