//import { useParams } from "react-router-dom";
import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { Heading } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import Loadingmini from '../components/loadingmini';
import { FaWindowClose } from 'react-icons/fa';
import { Button, View, Divider, Flex, Text } from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

export default function ChatMessages({userJwt}) {
  //let params = useParams();
  const [is_loading, updateIsLoading] = React.useState(false);
  const [res, updateRes] = useState({status:"loading...",rows:[]})
  const [count, setCount] = useState(0);

  const columns = [
    {
        headerName: 'Sent Timestamp',
        field: 'sql_timestamp',
        filter: true,
        flex:2
    },
    {
        headerName: 'Message',
        field: 'message',
        filter: true,
        flex:6
    },
    {
        headerName: 'Service Level',
        field: 'service_level',
        filter: true,
        flex:2
    },
    {
        headerName: ' ',
        field: 'id',
        filter: true,
        flex:1,
        cellRenderer: params => {
            let val = params.value
            return( <FaWindowClose onClick={() => setMessageRead(val)} style={{cursor: 'pointer', height:22, width:22}}/>)
        }
    }
];

  async function fetchChatMessages() {
    updateIsLoading(true);
    try {
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":"mychatmessages"
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        updateRes(res)
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res) 
    }
    updateIsLoading(false)
  }

  useEffect(() => {
    fetchChatMessages();
  },[count, userJwt])


  async function setMessageRead(chatId)
  {
    updateIsLoading(true)
    try {
        console.log('chat_id:'+chatId)
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt":userJwt,
            "api_endpoint":"mychatmessages",
            "chat_id":chatId
        }};
        const res = await API.get('adminWebTools','/ago',myInit)
        console.log(JSON.stringify(res))
        updateRes(res)
        updateIsLoading(false)
        //fetchChatMessages()
    }
    catch (e) {
        //const res = {status:"Error retrieving User information - email us at info@joinago.com: "+toString(e)}
        //updateRes(res)
      updateIsLoading(false)
    }
  }

  return (
    <Flex
      direction="column"
    >
      <View style={{padding:"1rem"}}>
        <Divider />
        {
            is_loading ? <Loadingmini /> : 
            <Flex 
                direction='column'
            >
                <Heading level={2}>Messages</Heading>
                <Text style={{width:'100%'}}>These are unread messages for you and your fleet.</Text>
                <View>
                    <Button onClick={() => setCount(count + 1)}>
                        PRESS TO GET LATEST UPDATES
                    </Button>
                </View>
                <Divider />
                <Text><i>Dismissing a fleet-wide message dismisses it for the entire fleet.</i></Text>
                <View style={{width:"100%"}}>
                    <View 
                        className="ag-theme-quartz" // applying the grid theme
                        style={{ height: 500 }} // the grid will fill the size of the parent container
                    >
                            <AgGridReact
                                rowData={res.rows}
                                columnDefs={columns}
                                rowSelection={'multiple'}
                                paginationPageSize={500}
                                paginationPageSizeSelector={[10, 25, 50]}
                                enableCellTextSelection={true}
                            />
                    </View>
                </View>
            </Flex>
        }
        <Divider />
      </View>
    </Flex>
  );
}