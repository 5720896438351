import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import Loadingmini from '../components/loadingmini';

export default function Userimageslicensefront({userId}) {
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const columns = [
        {
            headerName: 'Sender',
            field: 'sender_name_full',
            filter: true
        },
        {
            headerName: 'Recipient',
            field: 'recipient_name_full',
            filter: true
        },
        {
            headerName: 'Message',
            field: 'message',
            filter: true
        },
        {
            headerName: 'Sent Timestamp',
            field: 'sql_timestamp',
            filter: true
        },
        {
            headerName: 'Read Timestamp',
            field: 'read_timestamp',
            filter: true
        },
        {
            headerName: 'Datetime',
            field: 'created',
            filter: true
        },
        {
            headerName: 'PUSH json (if applicable)',
            field: 'push_json',
            filter: true
        }
  ];
  

  async function fetchPageData() {
    if(userId > 0)
    {
        updateIsLoading(true)
        let session = await Auth.currentSession()      
        let accessToken = session.getAccessToken()
        let jwt = accessToken.getJwtToken()
        const myInit = {queryStringParameters:{ 
            "back_end": process.env.REACT_APP_BACKEND,
            "jwt": jwt,
            "api_endpoint":"driverchathistory",
            "driver_id":userId
        }};
        try {
            const res = await API.get('adminWebTools','/ago',myInit)
            //console.log('driverchathistory user ' + userId + ' : ' + JSON.stringify(res))
            updateRes(res)
            updateIsLoading(false)
        }
        catch (e) {
            console.log('driverchathistory error: '+toString(e));
            updateIsLoading(false)
        }
        updateIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count, userId])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            <Divider />
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Driver Chat History</Heading>
                    <Text style={{width:'100%'}}>This is the history of in-app chat messages sent to/from the driver.</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <View style={{width:"100%"}}>
                        <View 
                            className="ag-theme-quartz" // applying the grid theme
                            style={{ height: 500 }} // the grid will fill the size of the parent container
                        >
                                <AgGridReact
                                    rowData={res.rows}
                                    columnDefs={columns}
                                    rowSelection={'multiple'}
                                    paginationPageSize={500}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    enableCellTextSelection={true}
                                />
                        </View>
                    </View>
                    <Divider />
                </Flex>
            }
        </View>
      </Flex>
  );
}
