import React, { useState, useEffect } from 'react'
import { API, Auth } from 'aws-amplify';
import { NavLink} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import { Flex, Divider, Text, Heading, View, Button} from '@aws-amplify/ui-react';

import { AgCharts } from 'ag-charts-react';

import Loadingmini from '../components/loadingmini';
import { useParams } from "react-router-dom";

export default function Vehicleutilization({paramVehicleId}) {
  let params = useParams();
  let tvehicleId = false
  if(paramVehicleId)
  {
    tvehicleId = paramVehicleId;
  } else {
    tvehicleId = params.vehicleId;
  }
  const [vehicleId, updateVehicleId] = React.useState(tvehicleId);
  const [is_loading, updateIsLoading] = React.useState(false);
  const [count, setCount] = useState(0);
  const [res, updateRes] = useState({status:"Contact support if this message stays for more than 30 seconds",rows:[]})
  const [seriesData, updateSeriesData] = useState([{day:0,value:0}])
  
  const [chartOptions, setChartOptions] = useState({
    data: seriesData,
    title: {text: 'Vehicle Utilization'},
    subtitle: {text: 'Last 31 Days'},
    series: [
      { type: 'bar', xKey: 'day', yKey: 'value' }
    ]
  })
  
  

  async function fetchPageData() {
    updateIsLoading(true)
    var thisVehicleId = vehicleId;
    if(paramVehicleId > 0)
    {
      thisVehicleId = paramVehicleId;
      updateVehicleId(thisVehicleId);
    }
    console.log('fetching vehicle data vehicle '+thisVehicleId);
    let session = await Auth.currentSession()      
    let accessToken = session.getAccessToken()
    let jwt = accessToken.getJwtToken()
    let user = await Auth.currentAuthenticatedUser(); 
    const myInit = {queryStringParameters:{ 
        "back_end": process.env.REACT_APP_BACKEND,
        "jwt": jwt,
        "api_endpoint":"vehiclesutilization",
        "vehicle_id":thisVehicleId
    }};
    try {
        const res = await API.get('adminWebTools','/ago',myInit)
        //console.log('vehicleutilization res: ' + JSON.stringify(res))
        //console.log('vehicleutilization resresp: ' + JSON.stringify(res['resp']))
        //console.log('vehicleutilization resresp0:  ' + JSON.stringify(res['resp'][0]))
        //console.log('vehicleutilization resresp0seriesdata: ' + JSON.stringify(res['resp'][0]['series_data']))
        updateSeriesData(res['resp'][0]['series_data'])
        let tchartOptions = chartOptions
        tchartOptions.data = res['resp'][0]['series_data']
        setChartOptions(tchartOptions)
        updateRes(res)
        updateIsLoading(false)
    }
    catch (e) {
        console.log('vehiclesutilization error: '+toString(e));
        updateIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPageData()
    },[count,params])



  return (
      <Flex
        direction="column"
      >
        <View style={{padding:"1rem"}}>
            {
                is_loading ? <Loadingmini /> : 
                <Flex 
                    direction='column'
                >
                    <Heading level={2}>Vehicle Utilization</Heading>
                    <Text style={{width:'100%'}}>Below is the percent of each day that this vehicle was rented for the last 31 days.</Text>
                    <View>
                        <Button onClick={() => setCount(count + 1)}>
                            PRESS TO GET LATEST UPDATES
                        </Button>
                    </View>
                    <Divider />
                    <Text><i>Hint: To search, long-click on mobile or click top-right on pc.</i></Text>
                    <Divider />
                    <View style={{width:"100%"}}>
                        <AgCharts options={chartOptions} />
                    </View>
                </Flex>
            }
        </View>
      </Flex>
  );
}
